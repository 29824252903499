const Colors = [
    {name:"grey", 		hex:"#999999", rgb:[153, 153, 153]},
    {name:"orange", 	hex:"#E69F00", rgb:[230, 159, 0]},
    {name:"green", 		hex:"#009E73", rgb:[0,158,115]},
    {name:"yellow",		hex:"#F0E442", rgb:[240, 228, 66]},
    {name:"blue",			hex:"#0072B2", rgb:[0, 115, 178]},
    {name:"red", 			hex:"#d61b1b", rgb:[214, 27, 27]},
    {name:"purple", 	hex:"#CC79A7", rgb:[204, 121, 167]}
]

export {Colors};