/**
 * UniqueRandomGenerator is a class that generates random integers in a range, excluding the numbers that have been generated before, while in the randomized buffer.
 */
export class UniqueRandomGenerator {
    constructor() {
        this.min = null;
        this.max = null;
        this.buffer = [];
        this.bufferSize = 0;
        this.bufferSizeSet = false;
    }

    setBufferSize(bufferSize) {
        if (this.bufferSize !== Number(bufferSize)) {
            this.bufferSize = Number(bufferSize);
            this.bufferSizeSet = true;
        }
        return this;
    }
    setMin(min) {
        if (this.min !== Number(min)) {
            this.min = Number(min);
            this.updateRange();
        }
        return this;
    }

    setMax(max) {
        if (this.max !== Number(max)) {
            this.max = Number(max);
            this.updateRange();
        }
        return this;
    }

    setMinMax(min, max) {
        this.setMin(min);
        this.setMax(max);
        return this;
    }

    updateRange() {
        if (this.min !== null && this.max !== null) {
            this.range = this.max - this.min + 1;
            if (!this.bufferSizeSet) {
                this.updateBufferSize();
                this.bufferSizeSet = true;
            }
        }
    }

    /**
     * Set the buffer size to a random number between 0 and this.range - 1, so that the buffer size changes every time it's exhausted.
     */
    updateBufferSize() {
        this.bufferSize = Math.floor(Math.random() * (this.range - 1));
    }

    /**
     * Generates a cryptographically secure random integer within a specified range.
     * This function ensures that the generated number hasn't been recently produced
     * by maintaining a buffer of previously generated numbers.
     *
     * @function
     * @this {object} Expected to have properties: `min`, `range`, `buffer`, `bufferSize`,
     *                and a method `updateBufferSize`.
     * @returns {number} A random integer between `this.min` (inclusive) and
     *                   `this.min + this.range - 1` (inclusive).
     *
     * @example
     * // Assuming an object context with min = 10, range = 5
     * getRandomIntegerInRange.call(context); // Returns a number between 10 and 14 (both inclusive)
     *
     * @remarks
     * - The function uses `window.crypto.getRandomValues` for cryptographic randomness.
     * - The buffer mechanism ensures a unique number generation until the buffer is full.
     *   Once the buffer reaches its size limit (`this.bufferSize`), the oldest number is removed.
     */
     getRandomIntegerInRange() {
        const bitSize = Math.ceil(Math.log2(this.range));
        const byteSize = Math.ceil(bitSize / 8);
        let randomBytes, randomNumber;

        do {
            randomBytes = new Uint8Array(byteSize);
            window.crypto.getRandomValues(randomBytes);
            randomNumber = 0;
            for (let i = 0; i < byteSize; i++) {
                randomNumber |= randomBytes[i] << (8 * i);
            }
            randomNumber = randomNumber % this.range + this.min;
        } while (this.buffer.includes(randomNumber) && this.buffer.length < this.range - 1);

        if (this.buffer.length >= this.bufferSize) {
            this.buffer.shift();
            // Update bufferSize after the buffer is reset
            this.updateBufferSize();
        }
        this.buffer.push(randomNumber);

        return randomNumber;
    }
}