import DataClient from "../../api/DataClient";
const api = new DataClient();


export async function startGamePlay({ commit, state, dispatch, getters, ...context }, data) {
    const token = context.rootState.data.token;
        let response = await api.post("/api/gameplay/game/" + data.gameId, { play_id: data.playId }, token);
        return response;
}

export async function updatePlayLevel({ commit, state, dispatch, getters, ...context }, data) {
    const token = context.rootState.data.token;

    const response = await api.post(
        "/api/gameplay/game/" + data.gameId + "/updateGamePlay",
        data, token
    );

    commit('data/setInMission', response.in_mission, { root: true });
}

export async function updateGameLevel({ commit, state, dispatch, getters, ...context }, data) {
    const token = context.rootState.data.token;

    return await api.post("/api/gameplay/users/set_game_level/" + data.game_id, data, token);
}

export async function saveTrialResults({ commit, state, dispatch, getters, ...context }, data) {
    const token = context.rootState.data.token;

    return await api.post("/api/gameplay/trials/save", data, token);
}

export async function setGameLevelAndSaveTrialResults({ commit, state, dispatch, getters, ...context }, data) {
    const token = context.rootState.data.token;

    return await api.post("/api/gameplay/set_trial_results/" + data.game_id, data, token);
}