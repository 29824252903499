import axios from 'axios';
// import store from src/store/index.js
/**
 * A class for making HTTP requests to a remote API using the axios library.
 */
export default class DataClient {
    /**
     * Create a new instance of DataClient.
     * @param {boolean} token - A bearer token for authentication.
     */
    constructor(token = false) {
        const domain = process.env.NODE_ENV === 'development'
            ? 'https://incrementalhealth.test/'
            : 'https://manage.paperscissorsandglue.com/';

        this.axios = axios.create({
            baseURL: domain,
            timeout: 6000,
            withCredentials: true,
        });
        this.axios.defaults.withCredentials = true;

        if (token) {
            // Set the authorization header if a token is provided
            this.setAxiosHeaders(token);
        } else {
            // Get the token from local storage
            const token = localStorage.getItem('access_token');
            if (token) {
                this.setAxiosHeaders(token);
            }
        }

        // Set an interceptor to handle 401 Unauthorized responses
        this.setupInterceptor();
    }

    setAxiosHeaders(token) {
        this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    /**
     * Make a GET request to the API.
     *
     * @param {string} url - The URL to make the request to.
     * @param {string|null} token - A bearer token for authentication.
     * @returns {Promise} - The response data or an error.
     */
    async get(url, token = null) {
        if (token) {
            this.setAxiosHeaders(token);
        }
        try {
            // Make the GET request
            const response = await this.axios.get(url,  { withCredentials: true });
            return response.data;
        } catch (error) {
            // Check if it's a network error
            if (!error.response) {
                // Handle network error (e.g., no internet connection)
                // Potentially use LocalDataClient here
                console.log("Network error, switching to local data...");
            } else {
                // Handle HTTP errors (401, 404, etc.)
                return Promise.reject(error);
            }
        }
    }

    /**
     * Make a POST request to the API.
     *
     * @param {string} url - The URL to make the request to.
     * @param {Object} data - The data to include in the request body.
     * @param {string|null} token - A bearer token for authentication.
     * @returns {Promise} - The response data or an error.
     * @todo Handle local data client
     * @todo Handle 401 response
     * @todo Handle 50* response
     */
    async post(url, data, token = null) {
        if (token) {
            this.setAxiosHeaders(token);
        }
        try {
            const response = await this.axios.post(url, data);
            return response.data;
        } catch (error) {
            if (!error.response) {
                // Handle network error
                console.log("Network error, switching to local data...");
            } else {
                // Handle HTTP errors
                return Promise.reject(error);
            }
        }
    }

    /**
     * Set up an interceptor to handle 401 Unauthorized responses.
     */
    setupInterceptor() {
        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        const interceptor = this.axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (!String(error).includes('401')) {
                    return Promise.reject(error);
                }

                axios.interceptors.response.eject(interceptor);

                return this.axios.post('/api/refresh', {
                    access_token: JSON.parse(localStorage.getItem('token')),
                })
                    .then((response) => {
                        // Set the new access token and update the store
                        this.setAxiosHeaders(response.data.access_token);
                        localStorage.setItem('access_token', response.data.access_token);
                        localStorage.setItem('refresh_token', response.data.refresh_token);

                        // Retry the original request
                        return axios(error.response.config);
                    })
                    .catch((error) => {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        this.axios.defaults.headers.common.Authorization = '';
                        return Promise.reject('expired');
                    });
            },
        );
    }
}
