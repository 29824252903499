// src/boot/register-service-worker.js

export default async ({ Vue }) => {
    // Check if service workers are supported
    if ('serviceWorker' in navigator) {
        // Register the service worker
        navigator.serviceWorker.register('/service-worker.js').then(reg => {
            // Listen for new service workers being installed
            reg.addEventListener('updatefound', () => {
                const newWorker = reg.installing;

                newWorker.addEventListener('statechange', () => {
                    if (newWorker.state == 'installed') {
                        const isRefresh = Boolean(navigator.serviceWorker.controller);
                        if (isRefresh) {
                            console.log('Page refresh detected. The service worker is now in control.');
                        } else {
                            console.log('Service worker is active and the page was not refreshed. It is now in control.');
                            window.location.reload();
                        }
                    }
                });
            });
        });
    }
};
