const routes = [
  {
    path: '/',
    component: () => import('layouts/MainWrapper.vue'),
    children: [
      {name: 'home', path: '', component: () => import('pages/Home.vue'), meta: {auth: true, title: 'Games'}},
      {name: 'gameplay', path: 'play/:game', component: () => import('pages/GamePlay.vue'), meta: {auth: true}},
      {name: 'login', path: '/login', component: () => import('pages/Login.vue')},
      {name: 'logout', path: '/logout', component: () => import('pages/Logout.vue'), meta: {auth: true}},
        {name: 'passwordrequest', path: '/password/request', component: () => import('pages/ResetPasswordRequest.vue')},
    ]
  }
];

// Always leave this as last one
// if (process.env.MODE !== 'ssr') {
//   routes.push({
//     path: '*',
//     component: () => import('pages/Error404.vue')
//   })
// }

export default routes;
