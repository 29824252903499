export function setLastClickedGame(state, game) {
    console.log(game, 'setLastClickedGame')
    state.lastClickedGame = game;
}

export function setScoreUpTrial(state, score) {
    state.scoreUpTrial = score;
}

export function setScoreUpHint(state, score) {
    state.scoreUpHint = score;
}

export function setChangedRound(state, changed) {
    state.changedRound = changed;
}

export function setScoreDownIncorrect(state, score) {
    state.scoreDownIncorrect = score;
}

export function setCurrentRound(state, currentRound) {
    state.currentRound = currentRound;
}

export function setCurrentTrial(state, currentTrial) {
    state.currentTrial = currentTrial;
}

export function setShowTimer(state, showTimer) {
    state.showTimer = showTimer;
}

export function setTimerBonus(state, timerBonus) {
    state.timerBonus = Math.ceil((100 - Math.ceil(timerBonus)) / 10) * 10;
}

export function setStopTimer(state, stopTimer) {
    state.stopTimer = stopTimer;
}

export function setLevelChangeRound(state, change) {
    state.levelChangeRound = change;
}

export function setQueryColors(state, colors) {
    state.query_colors = colors;
}

export function setMainInstructions(state, instructions) {
    state.mainInstructions = instructions;
}
export function setLoading(state, loading) {
    state.loading = loading;
}

export function setTheme(state, value) {
    state.theme = value;
}

export function setMovedUpALevel(state, value) {
    state.movedUpALevel = value;
}

export function setCurrentGame(state, value) {
    state.currentGame = value;
}

export function setCurrentStep(state, value) {
    state.currentStep = value;
}

export function setProgress(state, progress) {
    state.progress = progress;
}

export function setAssets(state, assets) {
    state.assets = assets;
}

export function setLevel(state, level) {
    state.level = level;
}

export function goUpLevel(state) {
    const newLevel = (state.level + state.levelChangeRound);
    console.log(newLevel, state.maxLevel, 'newLevel')
    if (newLevel <= state.maxLevel) {
        state.level = newLevel;
    } else {
        console.log('!!! max level reached !!!');
    }
}

export function setMaxLevel(state, data) {
    state.maxLevel = data;
}

export function goDownLevel(state) {
    state.level = Math.max(1, ( state.level - 1));
}

export function setGamePlayHints(state, hints) {
    state.currentGamePlayHints = hints;
}

export function setModalMessage(state, message) {
    state.modalMessage = message;
}

export function setTimer(state, time) {
    state.timer = Math.min(time, 100);
}

export function setHints(state, hints) {
    state.hints = hints;
}

export function setTextPlayHints(state, value) {
    state.textPlayHints = value;
}

export function setScore(state, score) {
    if (score < 0) score = 0;
    state.score = score;
}

export function increaseScore(state, score) {
    state.score = state.score + score;
}

export function decreaseScore(state, score) {
    state.score = Math.max(0, state.score - score);
}

export function setGameMode(state, data) {
    state.gameMode = data;
}

export function setTimerDuration(state, data) {
    state.timerDuration = data;
}

export function setTimerStopAfter(state, data) {
    state.timerStopAfter = data;
}

export function setGameId(state, data) {
    state.gameId = data;
}

export function setRoundId(state, data) {
    state.roundId = data;
}

export function addToPreviousLocations(state, data) {
    let locations = state.previousLocations;
    locations.push(data);
    state.previousLocations = locations;
}

export function clearPreviousLocations(state) {
    state.previousLocations = [];
}

export function setPlayId(state, data) {
    state.playId = data;
}

export function addToCorrectAnswers(state, data) {
    let answers = state.correctAnswers;
    answers.push(data);
    state.correctAnswers = answers;
}

export function clearCorrectAnswers(state) {
    state.correctAnswers = [];
}

export function addToAnswerSequence(state, data) {
    state.answerSequence.push(data);
}

export function removeLastNumberAnswerInSequence(state, data) {
    state.answerSequence.pop();
}

export function clearAnswerSequence(state) {
    state.answerSequence = [];
}

export function updateTrialsComplete(state, count) {
    state.trialsComplete = count;
}

export function updateTrialsCorrect(state, count) {
    state.trialsCorrect = count;
}

export function increaseTrialsCorrect(state) {
    state.trialsCorrect++;
}

export function resetTrialsCorrect(state) {
    state.trialsCorrect = 0;
}
