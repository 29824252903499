export default ({ Vue }) => {
    // Set the store from local storage before the app is created
    Vue.mixin({
        beforeCreate() {
            this.$store.commit('data/setStoreFromLocalStorage');
        }
    });

    // In your main.js or a similar entry file
    Vue.directive('disable-click', {
        update(el, binding) {
            if (binding.value) {
                el.style.pointerEvents = 'none';
            } else {
                el.style.pointerEvents = 'auto';
            }
        }
    });

};
