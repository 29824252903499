import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";
import Vue from 'vue';

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
    flare.light('rtVSjHdMPGykHcXn7Jt3b4SkNTPLjq8f');

}


Vue.use(flareVue);