export default {
  lastClickedGame: null,
  scoreUpTrial: 150,
  scoreUpHint: 50,
  levelChangeRound: 1,
  scoreDownIncorrect: 30,
  score: 0,
  currentRound: 1,
  currentTrial: 1,
  showTimer: false,
  stopTimer: false,
  timerBonus: 0,
  movedUpALevel: false,
  changedRound: false,
  currentGame: false,
  currentGameLevel: false,
  currentGameScore: 0,
  currentGamePlayHints: {},
  currentStep: false,
  answerSequence: [],
  correctAnswers: [],

  maxLevel: 1,
  hints: [],
  query_colors: 0,
  textPlayHints: '',
  timer: false,
  timerDuration: 0,
  timerStopAfter: 0,
  gameId: null,
  roundId: null,
  rounds: 5,
  trials: 2,
  level: null,
  gameMode: null,
  mainInstructions: [],
  theme: {
    backgroundColor: '#ffffff',
    foregroundColor: '#333',
    secondaryColor: 'pink',
    buttonColor: 'red'
  },
  progress: 0,
  assets: {
    images: {

    },
    audio: {

    }
  },
  trialsComplete: 0,
  trialsCorrect: 0,
  instructions: [

  ],
  previousLocations: [],
}
