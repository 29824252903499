export function setToken(state, token) {
    state.token = token;
}

export function setRefreshToken(state, token) {
    state.refresh_token = token;
}

export function setLoading(state, data) {
    state.loading = data;
}

export function setLoggedIn(state, data) {
    state.loggedIn = data;
}

export function destroyToken(state) {
    state.token = null;
    state.first_name = null;
    state.completed_plays = null;
    state.tickets = null;
    state.games = [];
}

export function setUser(state, data) {
    if (Object.keys(data).length > 0) {
        state.first_name = data.user.first_name;
        state.completed_plays = data.completed_plays;
        state.tickets = data.tickets;

        let formatted_games = [];

        for (const game of data.games.games) {
            let result = state.all_games.filter(obj => {
                return obj.sysname === game.sysname;
            });

            if (result) {

                const formatted_game = {...game, ...result[0]};
                formatted_games.push(formatted_game);
            }

        }

        state.games = formatted_games;
    } else {
        state.first_name = null;
        state.completed_plays = null;
        state.tickets = null;
        state.games = [];
    }
}

export function setLoggingOut(state, data) {
    state.loggingOut = data;
}

export function setGames(state, data) {
    // force update state.games to make sure it's reactivity is triggered
    state.games = [...data];
}

export function setInMission(state, data) {
    state.inMission = data;
}

export function setGameLevelData(state, data) {
    state.gameLevelData = data;
    state.rounds = 4;
    // get gameplay store
    this.dispatch('data/setGameplayMaxLevel', Number(data.max_level));

    // Number(data.game_settings.total_rounds && data.game_settings.total_rounds !== '' ? data.game_settings.total_rounds : 5)
    // state.trials = Number(data.game_settings.trials_per_round && data.game_settings.trials_per_round !== '' ? data.game_settings.trials_per_round : 3)
    // if data.game_settings.query_colors is not undefined we set this

    if (data.game_settings && data.game_settings.query_colors !== undefined) {
        state.query_colors = Number(data.game_settings.query_colors && data.game_settings.query_colors !== '' ? data.game_settings.query_colors : 1);
    }

    if (!state.play_id) state.play_id = data.play_id;
}

export function setPlayId(state, data) {
    state.play_id = data;
}

export function setGameLevelDataRound(state, data) {
    state.gameLevelData.round = data;
}

export function setRounds(state, rounds) {
    state.rounds = Number(rounds);
}

export function setTrials(state, trials) {
    state.trials = Number(trials);
}

export function setTrialStatus(state, data) {
    state.trialStatus.push(data);
}

export function clearTrialStatus(state) {
    state.trialStatus = [];
}

export function setInstructions(state, data) {
    state.instructions = data;
}

export function setMainInstructions(state, data) {
    state.mainInstructions = data;
}

export function setTickets(state, tickets) {
    state.tickets = tickets;
}

export function increaseTickets(state, tickets) {
    state.tickets += tickets;
    // we need to update localStorage or else this.setGameLevelData(gameData); will reset tickets to 0, because it's reading from localstorage
    localStorage.setItem('tickets', state.tickets);
}

export function setTicketsUpdated(state, updated) {
    state.ticketsUpdated = updated;
}

export function setError(state, error) {
    state.error = error;
}

export function setProgress(state, data) {
    state.progress = data;
}

export function setGameExited(state, data) {
    state.gameExited = data;
}

export function setModalMessage(state, data) {
    state.modalMessage = data;
}

export function setLoginError(state, data) {
    state.loginError = data;
}

export function resetModalMessage(state) {
    Object.assign(state.modalMessage, {title: false, body: false, actionButton: {action: null, text: null}});
}


export function setQueryColors(state, data) {
    state.query_colors = data;
}

export function addGamesPlayed(state, data) {
    if (data.completed) state.gamesPlayed += 1;
    localStorage.setItem('gamesPlayed', state.gamesPlayed);
}

export function setGamesPlayed(state, data) {
    state.gamesPlayed = data;
    localStorage.setItem('gamesPlayed', data);
}

export function setStoreFromLocalStorage(state) {
    // Load stored properties on page load
    const mirroredProperties = {
        'data': ['loggedIn', 'token', 'first_name', 'completed_plays', 'gameLevelData', 'tickets', 'all_games', 'games', 'user', 'play_id', 'gameExited']
        // 'module2': ['property2']
    };

    Object.keys(mirroredProperties).forEach((namespace) => {
        mirroredProperties[namespace].forEach((propertyName) => {
            const value = localStorage.getItem(`${propertyName}`);
            if (value !== null) {
                try {
                    state[propertyName] = JSON.parse(value);
                } catch (e) {
                    console.error(`Error parsing stored ${propertyName} value:`, value, e);
                }

            }
        });
    });

}






