import DataClient from "../../api/DataClient";

const api = new DataClient();

/**
 * Get the user data from the server
 * @param commit
 * @param state
 * @param dispatch
 * @param context
 * @param credentials
 * @returns {Promise<boolean>}
 */
export async function loginAndStoreToken({commit, state, dispatch, ...context}, credentials) {

  const token = state.token !== undefined ? state.token : null;
  const email = credentials && credentials.email !== undefined ? credentials.email : undefined;
  const password = credentials && credentials.password !== undefined ? credentials.password : undefined;

  // make sure we have both email and password
  if (email && password) {
    try {
      // Attempt to login
      let response = await api.post('/api/login', {
        email: credentials.email,
        password: credentials.password
      }, token);

      // set the games in the store
      commit('setGames', response.games.games);
      // set in_mission
      commit('setInMission', response.games.in_mission)
      localStorage.setItem('in_mission', response.games.in_mission);
      // // set games_completed_today
      // console.log('response.games_completed_today', response)
      // commit('setGamesCompletedToday', response.completed_plays)
      // // set ticket_counter
      commit('setTickets', response.tickets)

      // this is for global state, vs. local state
      commit('setToken', response.token);
      commit('setRefreshToken', response.refresh_token);
      commit ('setGamesPlayed', response.completed_plays);
      commit('setUser', response);
      api.setAxiosHeaders(response.token);
      localStorage.setItem('user_id', response.user.id);
      localStorage.setItem('user', JSON.stringify(response.user));

      return true;
    } catch (error) {
      // Set an error message telling the user that their login failed
      console.log('data', error.response.data.message);
      console.log(' status', error.response.status);
      console.log(error.response.headers);
    }
  }

  return false;
}

export async function getUserStats({commit, state, dispatch, ...context}) {
  let response = await api.get('/api/users/'+state.user.id+'/stats');
  console.log('response', response, state.user.id)
  commit("setGamesPlayed", response.completed_games);
  commit("setGames", response.games.games);
  commit("setInMission", response.games.in_mission);
  commit("setTickets", response.tickets);
}

export async function setGameplayMaxLevel(context, maxLevel) {
  context.commit('gameplay/setMaxLevel', maxLevel, { root: true });
}

/**
 * Destroy the token and clear the user data
 * @param commit
 * @param getters
 * @returns {Promise<unknown>}
 */
export function destroyToken({ commit, getters }) {
  const token = getters['data/getToken'];
  return api.post("/api/logout", token)
      .catch(error => {
        console.log('error logging out', error);
      })
      .finally(() => {
        localStorage.clear();
        api.setAxiosHeaders(null);
        commit("destroyToken");
        commit("setUser", {});
        commit("setLoggedIn", false);
      });
}

/**
 * Update the user's tickets
 * @param commit
 * @param state
 * @param dispatch
 * @param getters
 * @param context
 * @param data
 * @returns {Promise<unknown>}
 */
export async function updateTickets({ commit, state, dispatch, getters, ...context }, data) {
  const token = getters['data/getToken'];
  await api.post("/api/gameplay/users/set_game_level/" + data.game_id, {
        level: data.level,
        tickets: data.tickets
      }, token);
}