import {Howl, Howler} from 'howler';

export const audioPlayer = {
  player: null,
  effect_player: null,
  initPlayer () {
    this.player = new Howl({
      src: ['statics/init.mp3'],
      autoplay: false,
      loop: false,
        preload: true,
      volume: 0.1
    });

    this.player.play()

    this.effect_player = new Howl({
      src: ['statics/init.mp3'],
      autoplay: false,
        preload: true,
      loop: false,
      volume: 0.4
    });

    this.player.play()
  },
  startMusic  () {
    if (typeof this.player !== 'undefined') this.player.play()
  },
  stopMusic () {
    if (typeof this.player !== 'undefined') this.player.stop()
  },
  unloadMusic () {
    if (this.player !== null) this.player.unload()
  },
  setMusic (src) {
    if (this.player !== null) this.player.unload()

    this.player = new Howl({
            src: [src],
            autoplay: false,
            loop: true,
            volume: 0.2
          })

    this.player.play()
  },
  setEffect (src) {
    if (this.effect_player !== null) this.effect_player.unload()

    this.effect_player = new Howl({
            src: [src],
            autoplay: false,
            loop: false,
            volume: 0.1
          })

    this.effect_player.play()
  },
  setVolume(volume) {
    this.player.volume(volume)
  },
  // create mute method that will mute both music and effects
    mute () {
        Howler.mute(true)
    },
    unmute () {
        Howler.mute(false)
    }
}
