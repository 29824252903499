export default {
    gamePlay: {
        playTitle: "Howdy partner!",
        playInstructions: "<p>Shoot the targets by clicking or tapping them.</p><p>The targets will be different shapes, sizes, or colors</p>",
        playButton: 'Let’s go!'
    },
    rounds: {
        end: "End of Round {{round}}",
        playNext: "Play next round!",
        all: "Excellent! You got {{correct}} trials correct!",
        some: "Great job! You got {{correct}} trials correct in this round.",
        none: "Good try. Let's keep practicing!"
    },
    levelUp: {
        title: "Amazing job, level up!!!",
        bodyRender: (level) => `Moving to level ${level}, and you got some tickets!`
    },
    gameComplete: {
        playTitle: "Game Completed!",
        playInstructions: " ",
        playButton: 'Back'
    },
    instructions: {
        size: [
            {
                'title': 'Sharpshooters Wanted',
                'body': 'Shoot the targets by clicking them with your mouse.<br><br>\n' +
                    'The targets will be different shapes and sizes.',
                'buttonText': 'Shoot!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Sharpshooters Wanted',
                'body': 'Shapes can be small, medium or large.<br><br>\n' +
                    'And they change sizes every time that they appear.',
                'buttonText': 'Shoot!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Sharpshooters Wanted',
                'body': 'KEEP TRACK of each shape\'s size.<br><br>' +
                    'At the end, you\'ll click each shape\'s LAST SIZE.',
                'buttonText': 'Shoot!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Ready to start?',
                'body': 'Press yes to start, no to see the instructions again.',
                'buttonText': 'yes',
                'buttonText2': 'no',
                'buttonAction': 'goToPlay',
                'buttonAction2': 'goToBeginning'
            }
        ],
        color: [
            {
                'title': 'Sharpshooters Wanted',
                'body': 'Shoot the targets by clicking them with your mouse.\n<br><br>The targets will be different shapes and colors.',
                'buttonText': 'Shoot!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Sharpshooters Wanted',
                'body': 'Shapes come in lots of colors.\n<br><br>' +
                    '\n' +
                    'And they change color every time they appear!',
                'buttonText': 'Shoot!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Sharpshooters Wanted',
                'body': 'KEEP TRACK of each shape\'s color. \n<br><br>' +
                    '\n' +
                    'At the end, you\'ll click each shape\'s LAST COLOR.',
                'buttonText': 'Shoot!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Ready to start?',
                'body': 'Press yes to start, no to see the instructions again.',
                'buttonText': 'yes',
                'buttonText2': 'no',
                'buttonAction': 'goToPlay',
                'buttonAction2': 'goToBeginning'
            }
        ],
        cat: [
            {
                'title': 'Sharpshooters Wanted',
                'body': 'Shoot the targets by clicking them with your mouse.\n<br><br>' +
                    '\n' +
                    'The targets will belong to different GROUPS.',
                'buttonText': 'Shoot!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Sharpshooters Wanted',
                'body': 'The top of your screen will tell you which groups to watch for.\n<br><br>' +
                    '\n' +
                    'The groups change each round!',
                'buttonText': 'Shoot!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Sharpshooters Wanted',
                'body': 'KEEP TRACK of the LAST SHAPE from EACH GROUP.\n<br><br>' +
                    '\n' +
                    'At the end, you\'ll click the last shape from each group.',
                'buttonText': 'Shoot!',
                'buttonAction': 'goToNext'
            },
            {
                'title': 'Ready to start?',
                'body': 'Press yes to start, no to see the instructions again.',
                'buttonText': 'yes',
                'buttonText2': 'no',
                'buttonAction': 'goToPlay',
                'buttonAction2': 'goToBeginning'
            }
        ]
    }
};
