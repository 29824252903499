import {register} from 'register-service-worker';
import Notify from 'quasar/src/plugins/Notify.js';;


if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', function () {
        // New service worker became controlling
        if (this.controller && this.controller.state === 'activated') {
            window.location.reload();
        }
    });
}

register(process.env.SERVICE_WORKER_FILE, {
    ready() {
        console.log('App is being served from cache by a service worker.');
    },
    registered(registration) {
        console.log('Service worker has been registered.');
    },
    cached(registration) {
        console.log('Content has been cached for offline use.');
    },
    updatefound(registration) {
        console.log('New content is downloading.');
        Notify.create({
            position: 'center',
            spinner: true,
            message: 'New content is downloading, hold on a sec...',
            timeout: 1000
        })
    },
    updated(registration) {
        console.log('New content is available; please refresh.');
        Notify.create({
            position: 'center',
            spinner: true,
            message: 'New content is available; app is reloading...',
            timeout: 1000,
        });
        if ('caches' in window) {
            caches.keys().then(function (cacheNames) {
                return Promise.all(
                    cacheNames.map(function (cacheName) {
                        return caches.delete(cacheName);
                    })
                );
            }).then(function () {
                registration.waiting.postMessage({type: 'SKIP_WAITING'});
                window.location.reload();
            });
        }


    },
    offline() {
        console.log('No internet connection found. App is running in offline mode.');
    },
    error(err) {
        console.error('Error during service worker registration:', err);
    }
});
