import {audioPlayer} from './AudioPlayer';
import * as StringFormat from 'squirrelly';
import {differenceInMilliseconds} from "date-fns";
import {mapState, mapMutations, mapActions} from 'vuex';
import { getAssetSizeSuffix } from 'src/shared/responsiveAssetsLoader.js';
const uri = require('../components/GamePlay/Common/data/URLsCommon.js');

export default {
    /**
     * Map the data store state to the component
     */
    data() {
        return {
            assetSize: getAssetSizeSuffix,
            trialWasCorrect: false,
            sleepInterval: null,
            timeouts: [],
            delay: 50,
            typedChars: '',
            typedText: '',
            isMuted: localStorage.getItem('isMuted') === 'true',
            CDN: uri
        }
    },
    methods: {
        ...mapMutations('data', [
            'setUser',
            'setToken',
            'setLoading',
            'setLoggedIn',
            'setLoggingOut',
            'setGameLevelData',
            'setModalMessage',
            'resetModalMessage',
            'setGamesPlayed',
            'setTrials',
            'setTrialStatus',
            'setInMission',
            'clearTrialStatus',
            'setPlayId',
            'setProgress',
            'setGames',
            'setGameLevelDataRound',
            'setRounds',
            'setInstructions',
            'setMainInstructions',
            'setTickets',
            'increaseTickets',
            'setLoginError',
            'setTicketsUpdated',
            'setError',
            'setGameExited',
            'addGamesPlayed'
        ]),
        /**
         * Map the data store actions to the component
         */
        ...mapActions('data', [
            'destroyToken',
            'loginAndStoreToken',
            'getUserStats'
        ]),
        /**
         * Mark the game as played in the database and return to the home page
         * @returns {void}
         */
        goHome() {
            this.resetModalMessage();
            this.$router.push('/');
        },
        /**
         * Get the current date in the format of MM/DD
         * @returns {string}
         */
        getTodaysDate() {
            const d = new Date();
            let date = d.getDate();
            let month = d.getMonth() + 1;

            return month + '/' + date;
        },
        /**
         * Preloads assets by looping through the assets object and loading them into the browser cache
         * @returns {Promise<void>}
         */
        async preloadAssets() {
            // Flatten the assets because they can sometimes be nested
            const flattenedImages = this.flattenObject(this.assets.images);
            const flattenedAudio = this.flattenObject(this.assets.audio);
            const images = Object.values(flattenedImages);
            const audio = Object.values(flattenedAudio);

            // Combine the two arrays to leave room for other types of assets
            const sources = [...images, ...audio];
            const total = images.length + audio.length;

            // Zero out the progress
            this.setProgress(0);

            // Load each asset and store the resulting promise
            const assetPromises = sources.map((src) => {
                if (/\.(jpeg|jpg|gif|png)$/i.test(src)) {
                    return this.loadImage(src);
                } else if (/\.(mp3)$/i.test(src)) {
                    return this.loadAudio(src);
                }
            });

            // Wait for all promises to be settled, regardless of whether they were fulfilled or rejected
            const results = await Promise.allSettled(assetPromises);

            // Filter out the successful results
            const successfulResults = results.filter((result) => result.status === 'fulfilled');
            // Calculate the progress based on successful results
            const progress = Math.round((successfulResults.length / total) * 100);
            this.setProgress(progress);

            // Log the errors for the failed assets
            results.forEach((result, index) => {
                if (result.status === 'rejected') {
                    console.error(`Error loading asset: ${sources[index]}, error: ${result.reason}`);
                }
            });

            // If all assets loaded successfully, resolve the promise, otherwise reject it
            if (successfulResults.length === total) {
                console.log('All assets loaded successfully');
            } else {
                console.warn(`${successfulResults.length} out of ${total} assets loaded successfully`);
            }
        },

// Helper function to load an image and return a promise
        loadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    resolve();
                };
                img.onerror = (error) => {
                    reject(error);
                };
                img.src = src;
            });
        },
// Helper function to load an audio and return a promise
        loadAudio(src) {
            return new Promise((resolve, reject) => {
                const audio = new Audio();
                audio.addEventListener('canplaythrough', () => {
                    resolve();
                });
                audio.onerror = (error) => {
                    reject(error);
                };
                audio.src = src;
            });
        },
        /**
         * Unload the music from the audio player
         * @returns {void}
         */
        unloadMusic() {
            audioPlayer.unloadMusic();
        },
        /**
         * Stop the music from the audio player
         * @returns {void}
         */
        stopMusic() {
            audioPlayer.stopMusic();
        },
        /**
         * Set the music in the audio player
         * @param audio
         * @param gamePlay
         * @returns {void}
         */
        setMusic(audio, gamePlay = false) {
            if (audio !== null) {
                audioPlayer.setMusic(audio);
                if (gamePlay) {
                    // If we are in game play, we want to lower the volume to lessen distractions
                    audioPlayer.setVolume(0.04);
                } else {
                    // The intro music should be louder
                    audioPlayer.setVolume(0.2);
                }
            }
        },
        /**
         * Initialize the audio player
         * @returns {void}
         */
        initPlayer() {
            audioPlayer.initPlayer();
        },
        /**
         * Set a sound effect in the audio player
         * @param audio
         * @returns {void}
         */
        setEffect(audio) {
            if (audio !== null) {
                audioPlayer.setEffect(audio);
            }
        },
        setTicketEffect() {
            this.setEffect('statics/ticket-increase.mp3');
        },
        /**
         * Interpolate a string with the given values
         * @param message
         * @param values
         * @param useHTML
         * @returns {*|string}
         */
        formatText(message, values, useHTML = true) {

            if (useHTML) {
                if (message.startsWith('<p>')) return StringFormat.Render(message, values); // override <p> wrapper
                return '<p>' + StringFormat.Render(message, values) + '</p>';
            } else {
                return StringFormat.Render(message, values);
            }

        },
        /**
         * Reset the modal message
         * @returns {void}
         */
        modalMessageReset() {
            this.setModalMessage({title: false, body: false});
        },
        /**
         * Randomize the order of an array
         * @param array
         * @param makeCopy
         * @returns {*}
         */
        shuffleArray(array, makeCopy = false) {

            // Create a clone of the array if makeCopy is true.
            let currentArray = makeCopy ? [...array] : array;
            let m = currentArray.length, t, i;

            // While there remain elements to shuffle…
            while (m) {

                // Pick a remaining element…
                i = Math.floor(Math.random() * m--);

                // And swap it with the current element.
                t = currentArray[m];
                currentArray[m] = currentArray[i];
                currentArray[i] = t;
            }

            return currentArray;
        },
        /**
         * Round a number to the nearest tenth
         * @param num
         * @returns {number}
         */
        roundToNearestTenth(num) {
            return Math.round(num * 10) / 10;
        },
        /**
         * Get a random number in a range
         * @param min
         * @param max
         * @returns {number}
         */
        randomInRange(min, max) {
            let randomNum = Math.random() * (max - min) + min;
            return Math.floor(randomNum);
        },
        /**
         * Sleep for a given number of milliseconds, asynchronously
         * @param ms
         * @returns {Promise<unknown>}
         */
        sleep(ms) {
            return new Promise((resolve) => {
                this.sleepInterval = setTimeout(() => {
                    clearTimeout(this.sleepInterval);
                    resolve(ms);
                }, ms);

            });
        },
        /**
         * Create a range of numbers as an array
         * @param start
         * @param end
         * @returns {*[]}
         */
        range(start, end) {
            let list = [];
            for (let i = start; i <= end; i++) {
                list.push(i);
            }
            return list;
        },
        /**
         * Check if an object is empty
         * @param obj
         * @returns {boolean}
         */
        isEmpty(obj) {
            return [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;
        },
        /**
         * Get the intersection of two arrays
         * @param arrays
         * @returns {*}
         */
        intersection(arrays) {
            return arrays.reduce((a, b) => a.filter(c => b.includes(c)));
        },
        /**
         * Return a sample of n elements from an array
         * @param arr
         * @param n
         * @returns {any[]}
         */
        getSample(arr, n) {
            let result = new Array(n),
                len = arr.length,
                taken = new Array(len);
            if (n > len)
                throw new RangeError("getRandom: more elements taken than available");
            while (n--) {
                let x = Math.floor(Math.random() * len);
                result[n] = arr[x in taken ? taken[x] : x];
                taken[x] = --len in taken ? taken[len] : len;
            }
            return result;
        },
        /**
         * Get the ordinal position of a coordinate in a grid
         * @param coords
         * @param rowLength
         * @returns {number}
         */
        ordinalPositionFromCoordinates(coords, rowLength = 6) {
            return rowLength * (coords.y + 1) - (rowLength - (coords.x + 1)) - 1;
        },
        /**
         * Capitalize the first letter of a string
         * @param string
         * @returns {string}
         */
        capitalize(string) {
            return string[0].toUpperCase() + string.slice(1);
        },
        /**
         * Get the class list of an element
         * @param elt
         * @returns {{add: (function(*): *), toggle: (function(*): *), remove: (function(*): *)}}
         */
        classList(elt) {
            const list = elt.classList;

            return {
                toggle: function (c) {
                    list.toggle(c);
                    return this;
                },
                add: function (c) {
                    list.add(c);
                    return this;
                },
                remove: function (c) {
                    list.remove(c);
                    return this;
                }
            };
        },
        /**
         * Set a game as played
         * @param gameId
         * @param completed
         * @returns {void}
         */
        setGamePlayed(gameId, completed) {
            this.$store.commit('data/addGamesPlayed', {
                gameId: gameId,
                completed: completed
            });
        },

        /**
         * Create a unique key for HTML element :keys to assure they do not collide, but also to use for render busting
         * @returns {string}
         */
        createUniqueKey() {
            return Math.random().toString(36).substring(2, 32);
        },
        /**
         * Save the results of a trial
         * @param newRound
         * @returns {Promise<unknown>}
         */
        async saveTrialResults(newRound = false) {
            const trialStop = new Date();

            let responseTime = differenceInMilliseconds(trialStop, this.playStart);
            const data = {
                'response_time': responseTime,
                'round_id': this.roundId,
                'level': this.level,
                'correct': this.correctThisRound,
                'start': this.playStart,
                'stop': trialStop.getTime(),
                'details': null,
                'accuracy': this.trialsCorrect / this.trials,
                'createNewRound': newRound
            };

            return await this.$store.dispatch('gameplay/saveTrialResults', data);
        },
        /**
         * Update the game level for the user
         * @param newTickets
         * @returns {Promise<void>}
         */
        async updateGameLevel() {
            await this.$store.dispatch('gameplay/updateGameLevel', {
                game_id: this.gameId, level: this.level, newTickets: this.tickets
            });
        },
        async setGameLevelAndSaveTrialResults(newRound = false) {

            const trialStop = new Date();
            let responseTime = differenceInMilliseconds(trialStop, this.playStart);
            await this.$store.dispatch('gameplay/setGameLevelAndSaveTrialResults', {
                game_id: this.gameId,
                level: this.level,
                newTickets: this.tickets,
                response_time: responseTime,
                round_id: this.gameLevelData.round_id,
                play_id: this.play_id,
                correct: this.trialWasCorrect,
                start: this.playStart,
                stop: trialStop.getTime(),
                details: {},
                accuracy: this.trialWasCorrect ? 1 : 0,
                createNewRound: newRound
            });

            this.trialWasCorrect = false;
        },
        /**
         * Flatten an object
         * @param obj
         * @param parentKey
         * @returns {{}}
         */
        flattenObject(obj, parentKey = '') {
            let flattenedObject = {};

            for (let [key, value] of Object.entries(obj)) {
                const newKey = parentKey ? `${parentKey}.${key}` : key;

                if (typeof value === 'object' && value !== null) {
                    flattenedObject = {
                        ...flattenedObject,
                        ...this.flattenObject(value, newKey)
                    };
                } else {
                    flattenedObject[newKey] = value;
                }
            }

            return flattenedObject;
        },
        /**
         * Set the trial status to correct or incorrect to show the user check marks or x's in the trial HUD
         * @param trialWasCorrect
         */
        updateTrialStatus(trialWasCorrect) {
            this.setTrialStatus(trialWasCorrect);
        },
        getTrialClasses(index) {
            const status = this.trialStatus[index];
            let classes = [];
            if (index === this.currentTrial-1) {
                classes.push('active');
            }

            if (status) classes.push(status);

            return classes.join(' ');
        },
        characters(typedText) {
            this.timeouts.forEach(timeout => clearTimeout(timeout));
            this.timeouts = [];

            const chars = typedText.split("");
            this.typedChars = '';

            chars.forEach((char, index) => {
                const typedChar = setTimeout(() => {
                    this.typedChars += char;

                    if (index === chars.length - 1) {
                        clearTimeout(typedChar);
                    }
                }, index * this.delay);

                this.timeouts.push(typedChar);
            });
        }
    },
    computed: {
        /**
         * Map the data state to the component
         */
        ...mapState({
            loading: state => state.data.loading,
            loggedIn: state => state.data.loggedIn,
            loggingOut: state => state.data.loggingOut,
            loginError: state => state.data.loginError,
            token: state => state.data.token,
            error: state => state.data.error,
            first_name: state => state.data.first_name,
            tickets: state => state.data.tickets,
            ticketsUpdated: state => state.data.ticketsUpdated,
            completed_plays: state => state.data.completed_plays,
            gameLevelData: state => state.data.gameLevelData,
            modalMessage: state => state.data.modalMessage,
            inMission: state => state.data.inMission,
            all_games: state => state.data.all_games,
            user: state => state.data.user,
            trials: state => state.data.trials,
            trialStatus: state => state.data.trialStatus,
            play_id: state => state.data.play_id,
            rounds: state => state.data.rounds,
            progress: state => state.data.progress,
            games: state => state.data.games,
            gameExited: state => state.data.gameExited,
            gamesPlayed: state => state.data.gamesPlayed || localStorage.getItem('gamesPlayed'),
        }),
        /**
         * Check if conditions are met to display the modal popup
         * @returns {false|boolean|boolean}
         */
        displayModal() {
            return this.modalMessage !== undefined && this.modalMessage.title && this.modalMessage.body && !this.gameExited;
        }
    },
    watch: {
        isMuted(newVal, oldVal) {
            if (this.isMuted) {
                audioPlayer.mute();
                // set muted in local storage
                localStorage.setItem('isMuted', true);
            } else {
                audioPlayer.unmute();
                // set muted in local storage
                localStorage.setItem('isMuted', false);
            }
        }
    },
    mounted() {
        if (localStorage.getItem('isMuted') === 'true') {
            audioPlayer.mute();
        }
    },
    beforeDestroy() {

    }
};
