import {mapState, mapMutations, mapActions} from 'vuex';
import {UniqueRandomGenerator} from '../tools/UniqueRandomGenerator';
// this is okay for now but we should probably restructure the game play copy so this isn't taken from a specific game
import gamePlayCopy from '../components/GamePlay/Games/ShapeShooter/data/copy';
import {Colors} from 'src/components/GamePlay/Common/data/Colors.js';
import { clickRateLimit } from 'src/shared/ClickRateLimit.js';

export default {
    data() {
        return {
            colorValues: Colors,
            timerInterval: null,
            clickRateLimit: clickRateLimit
        };
    },
    methods: {
        /**
         * Map the gameplay store state to the component
         */
        ...mapMutations('gameplay', [
            'setLastClickedGame',
            'setCurrentStep',
            'setCurrentRound',
            'setCurrentGame',
            'setCurrentTrial',
            'setTimer',
            'setLevel',
            'goUpLevel',
            'goDownLevel',
            'setScore',
            'increaseScore',
            'decreaseScore',
            'setTextPlayHints',
            'setHints',
            'setAssets',
            'setGameMode',
            'setTheme',
            'setMovedUpALevel',
            'setTimerDuration',
            'setTimerStopAfter',
            'setStopTimer',
            'setShowTimer',
            'setTimerBonus',
            'setGameId',
            'setRoundId',
            'setQueryColors',
            'setPlayId',
            'setScoreUpHint',
            'setMainInstructions',
            'addToCorrectAnswers',
            'clearCorrectAnswers',
            'addToAnswerSequence',
            'removeLastNumberAnswerInSequence',
            'clearAnswerSequence',
            'updateTrialsComplete',
            'updateTrialsCorrect',
            'increaseTrialsCorrect',
            'resetTrialsCorrect',
            'addToPreviousLocations',
            'clearPreviousLocations'
        ]),
        /**
         * Map the gameplay store actions to the component
         */
        ...mapActions('gameplay', [
            'getInitialGameSettings',
            'startGamePlay',
            'updatePlayLevel',
            'updateGameLevel',
            'saveTrialResults'
        ]),
        /**
         * Initialize the game component for a new game
         * @param gameId
         * @param gameName
         * @param assets
         * @param instructions
         * @param backgroundImage
         * @returns {Promise<void>}
         */
        async initializeGame({gameId, gameName, assets, instructions, backgroundImage}) {
            this.setGameId(gameId);
            this.setCurrentGame(gameName);
            this.setCurrentStep('loading');
            this.setAssets(assets);
            this.setMainInstructions(instructions);

            this.setScore(0);
            this.setCurrentTrial(1);
            this.setCurrentRound(1);
            this.setLevel(1);

            this.setTheme({
                backgroundImage: backgroundImage
            });

            await this.preloadAssets();
            this.setLoading(false);
            this.setCurrentStep('introduction');
        },
        /**
         * Start the timer
         * @param targetDuration
         * @param isAsync
         * @returns {Promise<void>|void}
         */
        startTimer(targetDuration = 200, isAsync = false) {
            targetDuration = targetDuration * 100;
            this.setTimer(0);
            this.setStopTimer(false);
            this.setShowTimer(true);

            const startTime = Date.now();
            let resolve;  // This will be assigned if isAsync is true

            const updateTimer = () => {
                const elapsed = Date.now() - startTime;
                if (this.stopTimer || elapsed >= targetDuration) {
                    this.setTimerDuration(elapsed);
                    this.setStopTimer(false);
                    if (elapsed >= targetDuration) {
                        this.setTimer(100);
                    }

                    if (isAsync) resolve('timer done');
                    this.clearTimerInterval();
                    return;  // Exits the loop
                }

                const percentage = (elapsed / targetDuration) * 100;
                this.setTimer(Math.min(percentage, 100));
                this.timerInterval = requestAnimationFrame(updateTimer);  // Schedule the next frame
            };

            if (isAsync) {
                return new Promise((_resolve) => {
                    resolve = _resolve;
                    updateTimer();  // Start the loop
                });
            }

            updateTimer();  // Start the loop
        },
        clearTimerInterval() {
            if (this.timerInterval) {
                cancelAnimationFrame(this.timerInterval);
                this.timerInterval = null;
            }
        },
        /**
         * Stop and hide the timer
         */
        stopAndHideTimer() {
            this.setStopTimer(false);
            this.setShowTimer(false);
            this.setTimer('false');
            this.clearTimerInterval();
        },
        /**
         * Get a random integer in a range, inclusive of min and max values.
         * @param min
         * @param max
         * @returns {number}
         */
        getRandomIntegerInRange(min, max) {
            min = +min; // Ensure min and max are treated as numbers
            max = +max;
            if (!this.validateRangeInputs(min, max)) {
                return NaN; // Return NaN to indicate an error condition
            }
            // Get and return a random integer within the range
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        getRandomFloatInRange(min, max) {
            min = +min; // Ensure min and max are treated as numbers
            max = +max;
            if (!this.validateRangeInputs(min, max)) {
                return NaN; // Return NaN to indicate an error condition
            }
            // Get and return a random float within the range
            return Math.random() * (max - min) + min;
        },
        validateRangeInputs(min, max) {
            if (!Number.isFinite(min) || !Number.isFinite(max)) {
                console.error("Error: Min and max must be finite numbers.");
                return false;
            }
            if (isNaN(min) || isNaN(max)) {
                console.error("Error: Min and max must be numbers.");
                return false;
            }
            if (min > max) {
                console.error("Error: Min cannot be greater than max.");
                return false;
            }
            return true; // Inputs are valid
        },        
        /**
         * Returns an instance of the UniqueRandomGenerator class
         * @returns {UniqueRandomGenerator}
         */
        createUniqueRandomGenerator() {
            return new UniqueRandomGenerator();
        },
        /**
         * Get a random character from the alphabet
         * @returns {string}
         */
        getRandomCharacter() {
            return (String.fromCharCode(this.getRandomIntegerInRange(65, 90)));
        },
        /**
         * Get a formatted stimulus display interval frin the game level data
         * @returns {number}
         */
        getStimulusDisplayInterval() {
            try {
                let interval = Number(this.gameLevelData.game_settings.stimulus_display_interval);
                return interval * 1000;
            } catch (e) {
                return 0;
            }
        },
        /**
         * Get a random level ISI from the game level data
         * @returns {number}
         */
        getRandomLevelISI() {
            let settings = this.gameLevelData.game_settings;

            if (settings === undefined) {
                return 0;
            }

            return this.getRandomIntegerInRange(settings.isi_min, settings.isi_max) * 1000;
        },
        /**
         * Save the trial results and create a new round if required
         * @returns {Promise<null>}
         */
        async saveTrialAndCreateNewRound() {
            let roundId = null;
            if (this.remainingTrials || this.gamePlayComplete) {
                await this.saveTrialResults({
                    round_id: this.roundId,
                    createNewRound: true
                    // correct
                    // stop
                    // response_time
                    // accuracy
                });
            }

            if (this.isEndOfRound) {
                roundId = await this.saveTrialResults(true);
            }

            if (roundId !== null) this.roundId = roundId;

            return roundId;
        },
        /**
         * @method showMovedUpALevelModal
         * @description Show the moved up a level modal
         * @returns {void}
         */
        showMovedUpALevelModal() {
            this.setMovedUpALevel(false);

            this.setModalMessage({
                title: gamePlayCopy.levelUp.title,
                body: gamePlayCopy.levelUp.bodyRender(this.level),
                actionButton: {
                    action: !this.gamePlayComplete ? this.goToNextTrial : this.showEndOfGameModal,
                    text: gamePlayCopy.rounds.playNext
                }
            });
        },
        /**
         * Check if the current trial is the first trial of the game
         * @returns {boolean}
         */
        isFirstPlay() {
            return this.currentTrial === 1 && this.currentRound === 1;
        },
        /**
         * Exit the game and return to the home page
         * @returns {Promise<void>}
         */
        async leaveGame(incomplete=false) {
            //we need to at least record tickets.
            await this.updateGameLevel({
                game_id: this.gameId, level: this.level, newTickets: this.tickets
            });

            if (incomplete && this.play_id) {
                await this.updatePlayLevel({
                    gameId: this.gameId,
                    playId: this.play_id,
                    levelId: this.level,
                    stopTime: new Date().getTime(),
                    completed: false
                });
            }

            this.unloadMusic();
            this.setCurrentGame(null);
            this.setCurrentStep(null);
            this.setGameExited(true);
            // return to main arcade background

            this.modalMessageReset();
            this.setTimer(false);
            this.setStopTimer(true);
            console.log('leave game');


            await this.$router.push({name: 'home'});
        },
        setNextTrial() {
            this.setCurrentTrial(this.trials === this.currentTrial ? 1 : this.currentTrial + 1);
        },
        reloadGame() {
            this.$router.go();
        },
        startNewRound() {
            if (!this.remainingTrials) {
                this.setCurrentRound(this.currentRound + 1);
                this.setCurrentTrial(1);
                this.clearTrialStatus();
            } else {
                this.setNextTrial();
            }
        },
        showEndOfGameModal() {
            console.log('show end of game modal');
            this.setGamePlayed(this.gameId, this.getTodaysDate());
            this.setModalMessage({
                title: gamePlayCopy.gameComplete.playTitle,
                body: gamePlayCopy.gameComplete.playInstructions,
                actionButton: {action: this.goHome, text: gamePlayCopy.gameComplete.playButton}
            });
        }
    },
    computed: {
        /**
         * Map state properties from the gameplay store
         */
        ...mapState({
            answerSequence: state => state.gameplay.answerSequence,
            assets: state => state.gameplay.assets,
            correctAnswers: state => state.gameplay.correctAnswers,
            currentGame: state => state.gameplay.currentGame,
            currentRound: state => state.gameplay.currentRound,
            currentStep: state => state.gameplay.currentStep,
            currentTrial: state => state.gameplay.currentTrial,
            gameId: state => state.gameplay.gameId,
            gameMode: state => state.gameplay.gameMode,
            hints: state => state.gameplay.hints,
            lastClickedGame: state => state.gameplay.lastClickedGame,
            level: state => state.gameplay.level,
            levelChangeRound: state => state.gameplay.levelChangeRound,
            mainInstructions: state => state.gameplay.mainInstructions,
            movedUpALevel: state => state.gameplay.movedUpALevel,
            previousLocations: state => state.gameplay.previousLocations,
            progress: state => state.gameplay.progress,
            query_colors: state => state.gameplay.query_colors,
            score: state => state.gameplay.score,
            scoreDownIncorrect: state => state.gameplay.scoreDownIncorrect,
            scoreUpHint: state => state.gameplay.scoreUpHint,
            scoreUpTrial: state => state.gameplay.scoreUpTrial,
            showTimer: state => state.gameplay.showTimer,
            timerBonus: state => state.gameplay.timerBonus,
            timerStopAfter: state => state.gameplay.timerStopAfter,
            textPlayHints: state => state.gameplay.textPlayHints,
            theme: state => state.gameplay.theme,
            timer: state => state.gameplay.timer,
            trialsCorrect: state => state.gameplay.trialsCorrect

        }),
        /**
         * Return the current step as a component name
         * @returns {string}
         */
        activeComponent() {
            let gameSteps = {
                loading: 'Loading',
                introduction: 'Introduction',
                instructions: 'Instructions',
                play: 'Play',
                success: 'Success',
                failure: 'Failure'
            };

            return gameSteps[this.currentStep];
        },
        /**
         * Check if there are remaining trials in the current round
         * @returns {boolean}
         */
        remainingTrials() {
            return this.currentTrial < this.trials;
        },
        /**
         *
         * @returns {boolean}
         */
        remainingRounds() {
            return this.currentRound < this.rounds;
        },
        /**
         * Check if there are remaining trials in the current round
         * @returns {boolean}
         */
        isEndOfRound() {
            return !this.remainingTrials && !this.remainingRounds;
        },
        /**
         * Check if the game play is complete
         * @returns {boolean}
         */
        gamePlayComplete() {
            return !this.remainingTrials && !this.remainingRounds;
        },
        /**
         * Create CSS styles for the game container
         * @returns {{background: string, backgroundSize: string}}
         */
        gameContainerStyle() {
            return {
                background: 'url(' + this.assets.images.backgroundPlay + ') no-repeat',
                backgroundSize: 'contain'
            };
        },
        /**
         * Create CSS styles for the safe container (this should maybe be moved to the Master Thief game)
         * @returns {{background: string, backgroundSize: string}}
         */
        safeContainerStyle() {
            return {
                background: 'url(' + this.assets.images.backgroundSafe + ') no-repeat',
                backgroundSize: 'contain'
            };
        }
    },
    watch: {
        /**
         * Watch for changes to the current step and unload the music if the step is not the introduction
         * @param val
         * @param old
         */
        currentStep(val, old) {
            if (val !== 'introduction' && old === 'introduction') this.unloadMusic();
        },
        level(newLevel, oldLevel) {
            this.setMovedUpALevel(newLevel > oldLevel && oldLevel !== 0 && oldLevel !== null);
        }
    },
    beforeCreate() {
        cancelAnimationFrame(this.timerInterval);
    },
    beforeDestroy() {
        cancelAnimationFrame(this.timerInterval);
    }
};
