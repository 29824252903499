//
//
//
//
//
//

import axios from 'axios'
import GlobalMixin from './shared/GlobalMixins.js';
import GamePlayMixin from "./shared/GamePlayMixin";
import uri from 'src/components/GamePlay/Common/data/URLs.js';

export default {
  name: 'App',
  mixins: [GlobalMixin, GamePlayMixin],
  data () {
    return {
      baseURL: uri === 'statics/' ? '../'+uri : uri,
    }
  },
  created () {
    // this.setCurrentGame = 'foo'
    const token = localStorage.getItem('access_token')
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }
  },
  watch: {
    theme: {
      immediate: true,
      handler(newVal) {
        if (this.theme.backgroundImage !== undefined) {
          document.body.style.background = `url(${newVal.backgroundImage})`;
        }
      }
    }
  }
}
