// responsiveAssetsLoader.js
import uri from 'src/components/GamePlay/Common/data/URLs.js';

export function getAssetSizeSuffix() {
  const screenWidth = window.innerWidth;

  if (screenWidth > 1800) {
    return 'large/';
  } else if (screenWidth > 1000) {
    return 'medium/';
  } else if (screenWidth >= 768) {
    return 'small/';
  } else {
    return ''; // Default to the smallest or original
  }
}

export function generateAssets(basePath, assets) {
  const sizeSuffix = getAssetSizeSuffix();
  const updatedAssets = {};

  for (let [type, typeAssets] of Object.entries(assets)) {
    updatedAssets[type] = {};

    for (let [assetName, assetPath] of Object.entries(typeAssets)) {
      // Handle if the assetPath is an array of images
      if (type === 'images' && Array.isArray(assetPath)) {
        updatedAssets[type][assetName] = assetPath.map(imageName => {
          return `${uri}${basePath}${type}/${sizeSuffix}${imageName}`;
        });
      } else if (type === 'images') {
        updatedAssets[type][assetName] = `${uri}${basePath}${type}/${sizeSuffix}${assetPath}`;
      } else {
        // For non-image assets, use the original path
        updatedAssets[type][assetName] = `${uri}${basePath}${type}$/{assetPath}`;
      }
    }
  }

  return updatedAssets;
}
