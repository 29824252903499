export default {
    loading: false,
    token: null,
    refresh_token: null,
    user_id: null,
    error: null,
    first_name: null,
    loggedIn: false,
    play_id: false,
    rounds: 5,
    trials: 2,
    trialStatus: [],
    instructions: [],
    mainInstructions: [],
    modalMessage: {title: false, body: false, actionButton: {action: null, text: null}},
    modalAction: {action: false, text: false},
    tickets: 0,
    ticketsUpdated: false,
    completed_plays: 0,
    loginError: false,
    loggingOut: false,
    gameExited: false,
    progress: 0,
    games: [],
    inMission: false,
    gameLevelData: {},
    gamesPlayed: 0,
    user: {},
    all_games: [
        {
            name: 'First Encounter',
            image: 'games/FirstEncounter/images/splash.webp',
            sysname: 'game_3',
            url: 'first-encounter',
            description: "The aliens have landed! Do they come in peace? You're Earth's last hope to find out!"
        },
        {
            name: 'Scout Trainer',
            image: 'games/ScoutTrainer/images/splash.webp',
            sysname: 'game_5',
            url: 'scout-trainer',
            description: 'The orc armies are coming and only you can stop them! Use your scouting magic to defeat the big armies and save the village!'
        },
        {
            name: 'Word Wheel',
            image: 'games/WordWheel/images/splash.webp',
            sysname: 'game_1',
            url: 'word-wheel',
            description: 'Spin the wheel and win! Find the hidden words for big fun and fabulous prizes!'
        },
        {
            name: 'Shape Shooter',
            image: 'games/ShapeShooter/images/splash.webp',
            sysname: 'game_2',
            url: 'shape-shooter',
            description: 'Sharpshooters wanted! Step right up and get your ticket to the fun. Show off your trick shooting and keep track to win!'
        },
        {
            name: 'The Hideout',
            image: 'games/HideOut/images/splash.webp',
            sysname: 'game_4',
            url: 'the-hideout',
            description: "so you wanna join our clubhouse, huh? Then you better know the passwords!"
        },
        {
            name: 'Short Circuit',
            image: 'games/ShortCircuit/images/splash.webp',
            sysname: 'game_6',
            url: 'short-circuit',
            description: 'The gigaflop circuits are flopping! Fix the diodes and initiate repairs to win!'
        },
        {
            name: 'The Decoder',
            image: 'games/TheDecoder/images/splash.webp',
            sysname: 'game_7',
            url: 'the-decoder',
            description: 'Explorers wanted! Translate the mysterious runes to uncover ancient secrets!'
        },
        {
            name: 'Master Thief',
            image: 'games/MasterThief/images/splash.webp',
            sysname: 'game_8',
            url: 'master-thief',
            description: 'Jewels and riches beyond belief are waiting for you - if you can crack the safe!'
        },
        {
            name: 'Sweet Sampler',
            image: 'games/SweetSampler/images/splash.webp',
            sysname: 'game_9',
            url: 'sweet-sampler',
            description: 'Panic at the candy factory! Can you sort the sweets from the treats and the fun size from the king size?'
        }
    ]
};
