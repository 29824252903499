// clickRateLimit.js
let lastClickTimes = {};

export function clickRateLimit(buttonName){
    const now = Date.now();
    const lastClickTime = lastClickTimes[buttonName] || 0;
    if (now - lastClickTime < 500) {
        return false;
    }

    lastClickTimes[buttonName] = now;
    return true;
}