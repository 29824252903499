import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';

Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default function () {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })

  Router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user_id');

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
      next('/login');
      return;
    }

    const title = to.meta.title;
    if (title) {
      document.title = 'Incremental Health: ' + title;
    }

    next();
  })

  return Router;
}
