import Vue from 'vue'
import Vuex from 'vuex'

import gameplay from './gameplay'
import data from './data'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export default function () {
  const store = new Vuex.Store({
    modules: {
      gameplay,
      data
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  });

  // Automatically persist the state of the following properties to LocalStorage. Corresponding LocalStorage fetch happens in src/boot/before-create.js
  const mirroredProperties = {
    'data': ['loggedIn', 'token', 'first_name', 'completed_plays', 'tickets', 'all_games', 'games', 'user', 'play_id', 'gameExited', 'modalMessage', 'modalAction'],
  };

  store.subscribe((mutation, state) => {
    // Get the namespace and property name from the mutation type
    const [namespace, propertyName] = mutation.type.split('/');

    // We need the property name, not the mutation name
    let property = propertyName.replace(/^set/, '').replace(/^[A-Z]/, str => str.toLowerCase());
    // Check if the mutated property is in the list of mirrored properties for the namespace
    if (mirroredProperties[namespace] && mirroredProperties[namespace].includes(property)) {
      // Store the property value as a JSON string in a separate LocalStorage item
      localStorage.setItem(`${property}`, JSON.stringify(state[namespace][property]));
    }
  });

  return store
}
